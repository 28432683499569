import React, { useState } from 'react';

function UserGrowthGoal() {
  const [currentUsers, setCurrentUsers] = useState('');
  const [goalUsers, setGoalUsers] = useState('');
  const [username, setUsername] = useState('');
  const [isMinor, setIsMinor] = useState(null); // For the under-18 check

  const handleSetUserGrowth = () => {
    // This is where you'd handle the logic for user growth submission
    console.log('User Growth Submitted', { currentUsers, goalUsers, username, isMinor });
  };

  return (
    <div>
      <h2>Set Your User Growth Goal</h2>
      
      <div className="input-container">
        <input
          type="number"
          className="search-input"
          placeholder="Enter current number of users"
          value={currentUsers}
          onChange={(e) => setCurrentUsers(e.target.value)}
        />
      </div>

      <div className="input-container">
        <input
          type="number"
          className="search-input"
          placeholder="Enter goal number of users for 2025"
          value={goalUsers}
          onChange={(e) => setGoalUsers(e.target.value)}
        />
      </div>

      <div className="input-container">
        <input
          type="text"
          className="search-input"
          placeholder="Enter your Roblox username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      <div>
        <p>Are you under 18?</p>
        <button onClick={() => setIsMinor(true)}>Yes</button>
        <button onClick={() => setIsMinor(false)}>No</button>
      </div>

      {isMinor === true && (
        <p>To proceed, please have a parent set up the wallet for you.</p>
      )}

      <button className="submit-btn" onClick={handleSetUserGrowth}>
        Submit User Growth Goal
      </button>
    </div>
  );
}

export default UserGrowthGoal;
