import React from 'react';
import './SponsorshipCards.css';

const SponsorshipCards = ({ sponsors }) => {
  return (
    <div className="sponsorship-cards-container">
      {sponsors.map((sponsor, index) => (
        <div 
          key={index} 
          className="sponsorship-card" 
          onClick={sponsor.action ? sponsor.action : null} // Add action if it exists
          style={{ cursor: sponsor.action ? 'pointer' : 'default' }} // Show pointer if clickable
        >
          <img src={sponsor.logoUrl} alt={sponsor.name} className="card-logo" />
          <h3>{sponsor.name}</h3>
        </div>
      ))}
    </div>
  );
};

export default SponsorshipCards;
