import React from 'react';
import { motion } from 'framer-motion';

const coinVariants = {
  initial: { y: '-100vh', opacity: 0 },
  animate: {
    y: '100vh',
    opacity: 1,
    transition: { duration: 3, ease: 'easeOut' },
  },
  exit: { opacity: 0 },
};

const WalletCreationAnimation = ({ message }) => {
  return (
    <div className="wallet-animation">
      <p className="wallet-message">{message}</p>
      <div className="coins">
        {[...Array(20)].map((_, index) => (
          <motion.div
            key={index}
            className="coin"
            variants={coinVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            style={{
              position: 'absolute',
              left: `${Math.random() * 100}vw`,
            }}
          >
            💰
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default WalletCreationAnimation;
