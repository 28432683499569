import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import gaussianData from './/gaussian_data.json';  // Adjust the path

// Register all necessary Chart.js components
Chart.register(...registerables);

// Function to bin `ev_multiples` into ranges for the histogram
function binData(ev_multiples, numBins = 20) {
  const minValue = Math.min(...ev_multiples);
  const maxValue = Math.max(...ev_multiples);
  const binSize = (maxValue - minValue) / numBins;
  const bins = Array(numBins).fill(0);

  ev_multiples.forEach(value => {
    const binIndex = Math.min(Math.floor((value - minValue) / binSize), numBins - 1);
    bins[binIndex]++;
  });

  return bins.map((count, index) => ({
    count,
    label: (minValue + index * binSize).toFixed(2),
  }));
}

const WealthEstimateChart = ({ goalSales, sales2024 }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) {
      console.error("Canvas reference not found!");
      return;
    }

    const ctx = chartRef.current.getContext('2d');
    console.log("Canvas context: ", ctx);

    // Log the incoming data for debugging
    console.log("goalSales: ", goalSales, "sales2024: ", sales2024);

    // Ensure goalSales and sales2024 are valid numbers
    const validatedGoalSales = parseFloat(goalSales);
    const validatedSales2024 = parseFloat(sales2024);

    if (isNaN(validatedGoalSales) || isNaN(validatedSales2024)) {
      console.error("Invalid sales data provided.");
      return;
    }

    // Bin the EV multiples data
    const binnedData = binData(gaussianData.ev_multiples, 20);  // Using 20 bins
    const labels = binnedData.map(bin => bin.label);  // X-axis labels based on bin ranges
    const histogramData = binnedData.map(bin => bin.count);  // Y values for the histogram (counts in each bin)

    // Generate Gaussian Y values based on real logic
    const gaussianFit = labels.map(label => {
      const x = parseFloat(label);
      return (1 / (gaussianData.std_dev * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((x - gaussianData.mean) / gaussianData.std_dev, 2));
    });

    const scaleFactor = Math.max(...histogramData) / Math.max(...gaussianFit);
    const scaledGaussianFit = gaussianFit.map(y => y * scaleFactor);

    // Create Chart
    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,  // X-axis labels mapped to bin ranges
        datasets: [
          {
            type: 'bar',
            label: 'Histogram',
            data: histogramData,  // Frequencies for histogram (Y values)
            backgroundColor: 'rgba(135, 206, 250, 0.5)',  // Semi-transparent blue
            borderWidth: 1,
          },
          {
            type: 'line',
            label: 'Gaussian Fit',
            data: scaledGaussianFit,  // Scaled Gaussian Y values
            borderColor: 'red',
            fill: false,
            tension: 0.4,  // Smooth curve
          },
        ],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Projected Revenue Increase',
            },
            ticks: {
              beginAtZero: true,  // Ensure x-axis ticks start at 0
            },
          },
          y: {
            title: {
              display: true,
              text: 'Probability Density',
            },
            ticks: {
              beginAtZero: true,  // Ensure y-axis ticks start at 0
            },
          },
        },
      },
    });

    // Clean up chart on component unmount
    return () => chart.destroy();
  }, [goalSales, sales2024]);

  return (
    <div>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default WealthEstimateChart;
