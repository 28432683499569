import React from 'react';
import Typewriter from './Typewriter'; // Import the Typewriter component

// Function to format large numbers with a dollar sign and in a more user-friendly way, including Trillion
const formatNumber = (num) => {
  const absNum = Math.abs(num);
  if (absNum >= 1e12) {
    return `$${(num / 1e12).toFixed(1)} Trillion`;
  } else if (absNum >= 1e9) {
    return `$${(num / 1e9).toFixed(1)} Billion`;
  } else if (absNum >= 1e6) {
    return `$${(num / 1e6).toFixed(1)} Million`;
  } else if (absNum >= 1e3) {
    return `$${(num / 1e3).toFixed(1)} Thousand`;
  }
  return `$${num.toLocaleString()}`;
};

const WealthEstimate = ({ salesGoal, growthCondition }) => {
  const salesGoalValue = parseInt(salesGoal);
  const growthRate = growthCondition === 'negative' ? -0.05 : 0.05;
  const minWealthEstimate = formatNumber(0.6 * (1 + growthRate) * salesGoalValue);
  const maxWealthEstimate = formatNumber(25 * (1 + growthRate) * salesGoalValue);

  if (!salesGoal || !growthCondition) return null;

  const text = growthCondition === 'negative' 
    ? `Your business' owners personal wealth has already decreased, but by the end of 2025 it could decrease by as little as ${minWealthEstimate} or as much as ${maxWealthEstimate}, depending on your industry. Enter your industry to improve this estimate.` 
    : `Your networks wealth could grow by as little as ${minWealthEstimate} or as much as ${maxWealthEstimate}, depending on domain. Enter your business email address and take a screeshot of this page to refine this estimate with industry-specific data and unlock hidden growth capital.`;

  return (
    <div className="wealth-estimate">
      <Typewriter text={text} /> {/* Apply the Typewriter effect here */}
    </div>
  );
};

export default WealthEstimate;
