import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { motion } from 'framer-motion';

const CAGRChart = ({ lastYearSales, goalSales, monthsToGoal }) => {
  const data = [
    { name: 'Last Year', Sales: lastYearSales },
    { name: `Goal (${monthsToGoal} months)`, Sales: goalSales },
  ];

  const cagr = ((goalSales / lastYearSales) ** (1 / (monthsToGoal / 12)) - 1) * 100;

  // Format the Y-axis as currency without decimals
  const formatCurrency = (value) => {
    return `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
  };

  const chartHeight = 200; // Scale down the chart
  const chartWidth = 350;  // Scale down the chart

  return (
    <motion.div
      className="motion-chart" // Add a class for styling
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5, ease: 'easeInOut' }}
    >
      <BarChart 
        width={chartWidth}
        height={chartHeight}
        data={data}
        margin={{ top: 20, right: 5, left: 55, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={formatCurrency} tick={{ fill: '#82ca9d' }} stroke="#82ca9d" />
        <Tooltip />
        <Legend />
        <Bar dataKey="Sales" fill="#82ca9d" animationDuration={2500} animationEasing="ease-in-out" />
      </BarChart>

      {/*<h3 style={{ transition: 'opacity 1.5s ease-in-out', opacity: 1 }}>
        //Growth Goal %: {cagr.toFixed(2)}%
      </h3>*/}
    </motion.div>
  );
};

export default CAGRChart;
