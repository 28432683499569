import React, { useState, useEffect } from 'react';
import './Modal.css';
import SponsorshipCards from './SponsorshipCards';
import CAGRChart from './CAGRChart'; // Assuming chart component
import Typewriter from '../Typewriter'; // Your existing Typewriter component

const Modal = ({ showWalletModal, setShowWalletModal, lastYearSales, salesGoal }) => {
  // Initialize states for the modal
  const [headline, setHeadline] = useState("Committed to Growth?");
  const [tagline, setTagline] = useState("Get the Power to Make your Own Growth Fuel!");
  const [walletAddress, setWalletAddress] = useState(null);  // Store wallet address after login
  const [loading, setLoading] = useState(false);             // Handle loading state
  const [error, setError] = useState(null);                  // Handle error state
  const [showChart, setShowChart] = useState(false);         // State for chart trigger
  const [paragraphIndex, setParagraphIndex] = useState(0);   // Track dialogue progress



//  const handleTestRequest = async () => {
 // try {
   // const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/test', {
     // method: 'POST',
      //headers: {
    //    'Content-Type': 'application/json',
      //},
      //body: JSON.stringify({ message: 'Hello from frontend' }),
    //});

   // if (!response.ok) {
   //   throw new Error(`HTTP error! status: ${response.status}`);
   // }

//    const data = await response.json();
//    console.log(data); // Log the response from the backend
//  } catch (error) {
//    console.error('Error:', error); // Log any errors
//  }
//};



  useEffect(() => {
    if (showWalletModal) {
      document.body.style.overflow = 'hidden';  // Prevent background scroll
    } else {
      document.body.style.overflow = '';  // Restore scroll when modal is closed
    }

    return () => {
      document.body.style.overflow = '';  // Clean up on unmount
    };
  }, [showWalletModal]);

  // Array of paragraphs for the typewriter text
  const dialogue = [
    "You've taken the first steps towards reaching your goal. Now, find the GROWTH2025 token below if you're committed to reaching your 2025 goal!",
    "Great! Now, find the WEB3EMAIL Token to log in and get started!"
  ];

  // Ensure paragraphIndex is within bounds
  const validParagraphIndex = paragraphIndex < dialogue.length ? paragraphIndex : dialogue.length - 1;

  // Trigger chart appearance when specific paragraph is displayed
  useEffect(() => {
    if (paragraphIndex === 1) { // Trigger the chart when the second paragraph is reached
      setShowChart(true);
    }
  }, [paragraphIndex]);

  // Handle Web3Auth login
const handleLogin = async () => {
  setLoading(true);  // Start loading state
  setError(null);    // Reset error state

  try {
    // Send request to server.js (port env) for Web3Auth login
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
      method: 'POST',
      credentials: 'include',  // Ensure cookies are included if needed
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: 'yourUserId' })  // You may need to send a payload if required
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const data = await response.json();
    setWalletAddress(data.walletAddress);  // Set wallet address in state after successful login
  } catch (error) {
    setError(error.message);  // Show error message in case of failure
  } finally {
    setLoading(false);  // End loading state
  }
};

const handleOpenWeb3AuthWindow = () => {
  const web3AuthUrl = process.env.NODE_ENV === 'production' 
    ? 'https://wallet.goalblox.com' 
    : 'http://localhost:5173';  // Local server for development

  // Open the Web3Auth login window
  window.open(web3AuthUrl, '_blank', 'width=600,height=600');
};

  const sponsors = [
    {
      name: 'LUABLOX',
      logoUrl: 'https://cdn.sunpump.meme/public/logo/LUABLOX_TJ8rJk_TPg6wNsmsvKq.png',
      link: 'https://luablox.com',
    },
    {
        name: 'TRIPBALM',
        logoUrl: 'https://cdn.sunpump.meme/public/logo/TRIPBALM_TJ8rJk_MZXGLMPfigX6.png',
      },
      {
        name: 'NRGMEMES',
        logoUrl: 'https://cdn.sunpump.meme/public/logo/NRGMEMES_TJ8rJk_vPZOb26IckqR.png',
      },
      {
        name: '7DAYBOOKS',
        logoUrl: 'https://cdn.sunpump.meme/public/logo/7DayBooks_TJ8rJk_R4xxHv5ETHPG.webp',
      },
      {
        name: 'SIMFORCE',
        logoUrl: 'https://cdn.sunpump.meme/public/logo/SIMFORCE_TJ8rJk_vbK31SctlWNw.png',
      },
    
    {
      name: 'GROWTHNET',
      logoUrl: 'https://cdn.sunpump.meme/public/logo/GROWTHNET_TJ8rJk_9yh2180Q6kxj.png',
      link: 'https://aublox.com',
    },
    {
      name: 'Sign In With Email',  // Web3Auth login button
      logoUrl: 'https://cdn.sunpump.meme/public/logo/WEB3EMAIL_TJ8rJk_Fbr2s1asJxGD.png',  // Replace with relevant URL
      action: handleOpenWeb3AuthWindow  // Open the Web3Auth login window
    },
    {
      name: 'AUBLOX',
      logoUrl: 'https://cdn.sunpump.meme/public/logo/AUBLOX_TJ8rJk_SUjJKCfNr9xc.png',
      link: 'https://aublox.com',
    },
    {
      name: 'GROWTH2025',
      logoUrl: 'https://cdn.sunpump.meme/public/logo/GROWTH2025_TJ8rJk_leuALOYoqlMH.webp',
      action: () => {
        setHeadline("Congratulations!");
        setTagline("You got your first potential sponsor to help you reach your goal faster.");
        setParagraphIndex(paragraphIndex + 1);  // Display next paragraph in typewriter
      }
    }
  ];

  return (
    <div className={`wallet-modal ${showWalletModal ? 'wallet-modal-open' : ''}`}>
      <div className="modal-content">
        {/* Top Left: Headline and Tagline */}
        <div className="headline-container">
          <h1>{headline}</h1>
          <h3>{tagline}</h3>
        </div>

        {/* Left Side: Typewriter Text */}
        <div className="ai-section">
          <Typewriter 
            text={dialogue[validParagraphIndex]} 
            speed={50} // Adjust typing speed if needed
          />
        </div>

        {/* Top Right: Chart */}
        <div className="motion-chart">
          {showChart && <CAGRChart lastYearSales={lastYearSales} goalSales={salesGoal} monthsToGoal={12} />}
        </div>

        {/* Bottom: Sponsorship Cards */}
        <SponsorshipCards sponsors={sponsors} />

        {/* Display Wallet Address after login */}
        {walletAddress && <p>Wallet Address: {walletAddress}</p>}

        {/* Show loading or error messages */}
        {loading && <p>Logging in...</p>}
        {error && <p style={{ color: 'red' }}>Error: {error}</p>}
        
        {/* Close button for modal */}
        <button onClick={() => setShowWalletModal(false)}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
