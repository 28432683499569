import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { motion } from 'framer-motion';
import './App.css';
import Typewriter from './Typewriter';
import WealthEstimate from './WealthEstimate';
import WealthEstimateChart from './WealthEstimateChart';
import WalletCreationAnimation from './WalletCreationAnimation';
import TypingIndicator from './TypingIndicator';
import UserGrowthGoal from './UserGrowthGoal';
import Modal from './components/Modal';  // Importing the Modal component

import discovery from './assets/images/discovery.png';
import preclinical from './assets/images/preclinical.png';
import phase1 from './assets/images/phase1.png';
import phase2 from './assets/images/phase2.png';
import phase3 from './assets/images/phase3.png';
import market_approval from './assets/images/market_approval.png';
import launch from './assets/images/launch.png';
import phase4 from './assets/images/phase4.png';
//import config from './config/envConfig';
import CAGRChart from './components/CAGRChart';  // Import the chart component
import SponsorshipCards from './components/SponsorshipCards'; // Import the sponsorship cards component

const CoinsAnimation = lazy(() => import('./CoinsAnimation'));

function App() {
  const [salesGoal, setSalesGoal] = useState('');
  const [formattedSalesGoal, setFormattedSalesGoal] = useState('');
  const [lastYearSales, setLastYearSales] = useState('');
  const [formattedLastYearSales, setFormattedLastYearSales] = useState('');
  const [website, setWebsite] = useState('');
  const [industry, setIndustry] = useState('');
  const [email, setEmail] = useState('');
  const [showSalesQuestion, setShowSalesQuestion] = useState(false);
  const [showBorealisEffect, setShowBorealisEffect] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [growthCondition, setGrowthCondition] = useState(null);
  const [isWebsiteInput, setIsWebsiteInput] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [flyoutComplete, setFlyoutComplete] = useState(false);
  const [showWalletButton, setShowWalletButton] = useState(false);
  const [industrySet, setIndustrySet] = useState(false);
  const [imageContainerVisible, setImageContainerVisible] = useState(true);
  const [showTypingIndicator, setShowTypingIndicator] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);

  const inputRef = useRef(null);
  const lastYearSalesRef = useRef(null);
  const websiteInputRef = useRef(null);

  const [selectedGoal, setSelectedGoal] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [rotationStopped, setRotationStopped] = useState(false);
  const [showCoinsAnimation, setShowCoinsAnimation] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

  const images = [
    { src: discovery, goal: 'Discovery' },
    { src: preclinical, goal: 'Preclinical' },
    { src: phase1, goal: 'Phase I' },
    { src: phase2, goal: 'Phase II' },
    { src: phase3, goal: 'Phase III' },
    { src: market_approval, goal: 'Market Approval' }, // Market Approval before Launch
    { src: launch, goal: 'Launch' }, // Launch before Phase IV
    { src: phase4, goal: 'Phase IV' }, // Phase IV after Launch
  ];

  const [buttonText, setButtonText] = useState(`2025 ${images[0].goal} Goal`);
  const [headlineText, setHeadlineText] = useState(images[0].goal);
  const [buttonColor, setButtonColor] = useState('#fff');
  const [buttonFontColor, setButtonFontColor] = useState('#000');

  const buttonColors = ['#fff', '#00f', '#ff6347', '#008080', '#1e90ff', '#ffd700', '#ff69b4', '#2e8b57'];

  const getContrastColor = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 150 ? '#000' : '#fff';
  };

  useEffect(() => {
    if (!rotationStopped) {
      const interval = setInterval(() => {
        const nextIndex = (currentImageIndex + 1) % images.length;
        
        // Conditional check for the first button
        if (nextIndex === 0) {
          // Handling for the first button in the loop
          setButtonText(`2025 ${images[0].goal} Goal`);
          setHeadlineText(images[0].goal);
          setButtonColor('#fff'); // Default color for the first button
          setButtonFontColor('#000'); // Default font color for the first button
        } else {
          // Handling for all other buttons
          setButtonText(`2025 ${images[nextIndex].goal} Goal`);
          setHeadlineText(images[nextIndex].goal);
          setButtonColor(buttonColors[nextIndex]);
          setButtonFontColor(getContrastColor(buttonColors[nextIndex]));
        }
  
        setCurrentImageIndex(nextIndex);
      }, 5000);
  
      return () => clearInterval(interval);
    }
  }, [currentImageIndex, rotationStopped]);
  

  const handleInputChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    setSalesGoal(input);
    setFormattedSalesGoal(input);
  };

  const handleLastYearSalesChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    setLastYearSales(input);
    setFormattedLastYearSales(input);
  };

  const handleButtonClick = () => {
    setIsInputVisible(true);
    setRotationStopped(true);
  };

  const handleSalesKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSetGoal();
    }
  };

  const handleSetGoal = () => {
    setShowSalesQuestion(true);
    lastYearSalesRef.current?.focus();
  };

  const determineGrowthCondition = () => {
    const lastYearSalesValue = parseInt(lastYearSales);
    const salesGoalValue = parseInt(salesGoal);

    if (isNaN(lastYearSalesValue) || isNaN(salesGoalValue)) return;

    const growthRate = (salesGoalValue - lastYearSalesValue) / lastYearSalesValue;

    if (growthRate < -0.05) {
      setGrowthCondition('decline_recovery');
    } else if (growthRate < 0) {
      setGrowthCondition('negative');
    } else if (growthRate <= 0.02) {
      setGrowthCondition('low');
    } else if (growthRate <= 0.10) {
      setGrowthCondition('moderate');
    } else if (growthRate <= 0.20) {
      setGrowthCondition('high');
    } else if (growthRate <= 0.50) {
      setGrowthCondition('exponential');
    } else if (growthRate <= 1.00) {
      setGrowthCondition('hypergrowth');
    } else if (growthRate <= 2.00) {
      setGrowthCondition('ultra_hypergrowth');
    } else {
      setGrowthCondition('explosive');
    }
  };

  const handleSetHistory = () => {
    determineGrowthCondition();
    setShowBorealisEffect(true);
    setIsWebsiteInput(true);
    setFormattedLastYearSales('');
    setWebsite('');
    setShowCoinsAnimation(true);
    setImageContainerVisible(false);
    setTimeout(() => {
      websiteInputRef.current?.focus();
    }, 100);
  };

  const handleSetIndustry = async () => {
    if (!email.trim()) {
      console.log('No email entered.');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    setIndustry(email.split('@')[1]);
    setIndustrySet(true);
    setShowBorealisEffect(true);

    setShowWalletModal(true); // Trigger the modal
  };

  const handleGoalSelection = (goal) => {
    setSelectedGoal(goal);
    setRotationStopped(true);
    setIsInputVisible(true);

    setSalesGoal('');
    setFormattedSalesGoal('');
    setLastYearSales('');
    setFormattedLastYearSales('');
  };

  const handleCreateWallet = () => {
    setShowSuccessAnimation(true);
    setShowTypingIndicator(true);
  };

  const colorMap = {
    negative: { color: "#353535", comment: "Your year-end 2024 sales are estimated to be higher than your 2025 sales goal...", nextStep: "As a result, this isn't the tool for you..." },
    low: { color: "#7f7f7f", comment: "There are times when you simply must try to maintain...", nextStep: "Now may be your best chance to find hidden assets..." },
    moderate: { color: "#008080", comment: "You're growing steadily! Keep leveraging opportunities to accelerate growth.", nextStep: "Continue driving innovation." },
    high: { color: "#1e90ff", comment: "Impressive growth! Your business is on a fast track to success.", nextStep: "Keep up the momentum!" },
    exponential: { color: "#ffd700", comment: "Exceptional growth! Your business is expanding rapidly.", nextStep: "Focus on scaling operations efficiently." },
    hypergrowth: { color: "#ff69b4", comment: "Incredible! You've achieved hypergrowth, doubling your size.", nextStep: "Prepare to scale your operations quickly." },
    ultra_hypergrowth: { color: "#ff4500", comment: "Phenomenal! You've tripled your size. Ensure scalability.", nextStep: "Expand your operations and manage resources wisely." },
    explosive: { color: "#ff6347", comment: "Wow! Explosive growth! Consider scaling funding and partnerships.", nextStep: "Ensure your infrastructure can handle the expansion." },
    startup: { color: "#2e8b57", comment: "Congratulations on your startup! Early growth determines your path.", nextStep: "What industry are you in?" },
    decline_recovery: { color: "#ffa500", comment: "You've faced a downturn, but signs of recovery are emerging.", nextStep: "Strengthen your recovery strategy." }
  };

  const dynamicMessage = growthCondition && colorMap[growthCondition] ? (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Typewriter text={colorMap[growthCondition].comment} />
      </motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Typewriter text={colorMap[growthCondition].nextStep} />
      </motion.div>
    </>
  ) : null;

  return (
    <div className={`App ${showBorealisEffect ? 'borealis-effect' : ''}`}>
      <div className="logo-container">
        <span className="logo">PATENTBOTS.▽</span>
      </div>

      {showTypingIndicator && (
        <div className="typing-indicator-container" style={{ textAlign: 'center', position: 'absolute', top: '10px', width: '100%' }}>
          <TypingIndicator message="Congratulations!" />
        </div>
      )}

      <div className="main-container">
        <div className="left-section">
          <h1>Fuel Your {headlineText}</h1>
          <p>Reaching your goal requires capital and commitment.</p>

          {!isInputVisible ? (
            <button
              className="search-btn"
              onClick={handleButtonClick}
              style={{ backgroundColor: buttonColor, color: buttonFontColor }}
            >
              {buttonText}
            </button>
          ) : (
            <>
              {selectedGoal === 'User Growth' ? (
                <UserGrowthGoal />
              ) : (
                <>
                  {!showSalesQuestion ? (
                    <div className="input-container">
                      <input
                        type="text"
                        className="search-input"
                        placeholder={selectedGoal === 'User Growth' ? "Enter your best peak sales ($) guess" : "Enter best peak sales ($) guess"}
                        value={formattedSalesGoal}
                        onChange={handleInputChange}
                        onKeyPress={handleSalesKeyPress}
                        ref={inputRef}
                        autoFocus
                      />
                      {salesGoal ? (
                        <button className="submit-btn" onClick={handleSetGoal}>
                          Set Goal
                        </button>
                      ) : null}
                    </div>
                  ) : !isWebsiteInput ? (
                    <div className="input-container">
                      <input
                        type="text"
                        className="search-input"
                        placeholder={selectedGoal === 'User Growth' ? "Enter your total 2024 users" : "Months to next phase"}
                        value={formattedLastYearSales}
                        onChange={handleLastYearSalesChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSetHistory();
                          }
                        }}
                        ref={lastYearSalesRef}
                        autoFocus
                      />
                      {lastYearSales ? (
                        <button className="submit-btn" onClick={handleSetHistory}>
                          Set Time Goal
                        </button>
                      ) : null}
                    </div>
                  ) : (
                    <div className="input-container">
                      <input
                        type="text"
                        className="search-input"
                        placeholder={selectedGoal === 'User Growth' ? "Enter your Roblox username" : "Enter your business email"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSetIndustry();
                          }
                        }}
                        ref={websiteInputRef}
                        autoFocus
                      />
                      <button className="submit-btn" onClick={handleSetIndustry}>
                        Set Industry
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {showBorealisEffect && growthCondition && (
            <div className="response-message">
              <WealthEstimate salesGoal={salesGoal} growthCondition={growthCondition} />
              <WealthEstimateChart goalSales={salesGoal} sales2024={lastYearSales} />

              {showCoinsAnimation && (
                <Suspense fallback={<div>Loading animation...</div>}>
                  <CoinsAnimation spreadWidth="100%" />
                </Suspense>
              )}

              {showWalletButton && (
                <button className="create-wallet-btn" onClick={handleCreateWallet}>
                  Create Hidden Capital Wallet
                </button>
              )}

              {showSuccessAnimation && <WalletCreationAnimation />}
            </div>
          )}
        </div>

        {imageContainerVisible && (
          <div className="image-container">
            <Suspense fallback={<div>Loading images...</div>}>
              <motion.img
                key={images[currentImageIndex].src}
                src={images[currentImageIndex].src}
                alt={images[currentImageIndex].goal}
                onClick={() => handleGoalSelection(images[currentImageIndex].goal)}
                className="goal-image"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ width: '100%', objectFit: 'cover', borderRadius: '12px' }}
              />
            </Suspense>
          </div>
        )}
      </div>

      {showWalletModal && (
        <Modal
          showWalletModal={showWalletModal}
          setShowWalletModal={setShowWalletModal}
          lastYearSales={lastYearSales}
          salesGoal={salesGoal}
        />
      )}

    </div>
  );
}

export default App;
