import React, { useState, useEffect } from 'react';

function TypingIndicator() {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length === 3 ? '' : prevDots + '.'));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return <p>{dots}</p>;
}

export default TypingIndicator;
